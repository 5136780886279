export const useLayoutStore = defineStore('layoutStore', {
  state: () => ({
    sidebarOpen: false,
  }),
  actions: {
    toggleSidebar() {
      useIntercomBubbleHide(!this.sidebarOpen);
      this.sidebarOpen = !this.sidebarOpen;
    },
    closeSidebar() {
      this.sidebarOpen = false;
    },
  },
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useLayoutStore, import.meta.hot));
}
